import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import {ReactComponent as ArrowIcon} from '../../assets/arrow-right-icon.svg';

import {ReactComponent as CryptoIcon} from '../../assets/solar_close-circle-linear.svg';
import {deleteWalletAddress, getCustomersWallet, setWalletAddress} from "../../logic/server/api";
import {PublicKey} from "@solana/web3.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Claim = () => {
    const [walletAddress, SetWalletAddress] = useState('');
    const [error, SetError] = useState(false);
    const [walletAddressSaved, SetWalletAddressSaved] = useState('');
    let count = useSelector(state => state.counter.count);
    count = count || localStorage.getItem('points');
     useEffect(() => {
        getWallet();
    }, []);

    async function getWallet() {
        const resp = await getCustomersWallet();
        if (resp?.person?.crypto_wallet?.address) {
            SetWalletAddress(resp?.person?.crypto_wallet?.address);
            SetWalletAddressSaved(resp?.person?.crypto_wallet?.address);
        }
        console.log(resp);
    }
    async function deleteWallet() {
        return await deleteWalletAddress(walletAddress);
    }
    async function addWallet(walletToSave) {
        return await setWalletAddress(walletAddress || walletToSave);
    }
    const handleChange = (event) => {
        SetError(false);
        console.log(event.target.value);
        SetWalletAddress(event.target.value);
        if (event.target.value.length >= 44 ) {
            setWallet(event.target.value);
        }
    };
    const clearWallet = (event) => {
        if (SetWalletAddressSaved) {
            deleteWallet().then(res => {
                console.log(res);
                SetWalletAddress('');
                SetWalletAddressSaved('');
            }).catch(err => {
                SetError(true);
            });
        } else {
            SetWalletAddress('');
            SetWalletAddressSaved('');
        }
    };
  const setWallet = (addressSaved) => {
    console.log(walletAddress, addressSaved);
    const walletToSave = walletAddress || addressSaved;
    if (walletToSave) {
        try {
            const publicKey = new PublicKey(walletToSave);
            PublicKey.isOnCurve(publicKey.toBytes());
            if (walletAddressSaved) {
                deleteWallet().then(() => {
                    addWallet(walletToSave).then(() => {
                        SetWalletAddressSaved(walletToSave);
                        toast.success('Wallet address saved successfully!'); 
                    }).catch(err => {
                        SetWalletAddressSaved('');
                        SetError(true);
                        toast.error('Failed to save wallet address!'); 
                    });
                }).catch(err => {
                    SetWalletAddressSaved('');
                    SetError(true);
                    toast.error('Failed to delete existing wallet address!'); 
                });
            } else {
                addWallet(walletToSave).then(() => {
                    SetWalletAddressSaved(walletToSave);
                    toast.success('Wallet address saved successfully!'); 
                }).catch(err => {
                    SetWalletAddressSaved('');
                    SetError(true);
                    toast.error('Failed to save wallet address!'); 
                });
            }
        } catch (err) {
            toast.error('Invalid wallet address format!'); 
            return false;
        }
    }
};


    const navigateToWeb = () => {
        window.open('https://dev.tapcat-memecoin.techfunder.de/redeem', '_blank')
    }

        return (
        <TelegramScreen
            className={`main-wrapper`}  id='gesuredZone'>
            <div className='main-container main-container-dim'>
                <div className={'top-container'}>
                <div className={'redeem-cards-container gap-of-wrapper-of-ppurs'}>
              
                    <div>
                    <p className={'text-bold card__tasks--title-text'}>Redeem your tokens</p>
                        
                        
                    </div>
                    
                      <div className='wrapper-of-ppurs'>
                        
                          <p className='forms__bottom-text-title text-bold counter-of-ppurs'>{(!!count && count) || 0}</p>
                          
                          <p className='purr-counter-text'>PURR</p>
                          
                        </div>
                          
                
                    <div className='wrapper-for-crypto-wallet'>
                    
                     <form className='form-for-crypto-wallet'>
                      <label className='label-for-crypto-wallet'>
                        Connected wallet (Phantom Solana)
                         </label>
                        <input
                          type="text"
                          className={`input-for-crypto-wallet ${error ? 'input-error-text' : ''}`}
                          placeholder="0xdafea34..."
                          onChange={handleChange}
                          value={walletAddress}
                          required
                        />
                         <ArrowIcon className={`set-wallet ${walletAddress ? 'hide-button' : 'show-button'}`} onClick={setWallet}></ArrowIcon>
                         <CryptoIcon className={`crypto-icon ${walletAddress? 'show-button' : 'hide-button'}`} width="24" height="24" fill="#000"
                         onClick={clearWallet}/>
                    </form>
                    
                  <div className={`cards__redeem-button redeem-text-btn ${walletAddressSaved ? '' : 'disabled'}`} onClick={navigateToWeb}>
                      Redeem now
                      <ArrowIcon/>
                  </div>
                    
                    </div>
                    
                </div>
                </div>
                <div className='forms__bottom'>
                    <FooterFunction currentPage={'redeem'}/>
                </div>
            </div>
                  <ToastContainer
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                 
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

        </TelegramScreen>)

}
export default Claim;
