import React, {useEffect, useRef, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import arrow from '../../assets/arrow-right.svg';
import arrowBlack from '../../assets/arrow-black.svg';
import heartImg from '../../assets/heart.svg';
import lightningImg from '../../assets/lightning.svg';
import emojiImg from '../../assets/emoji.svg';
import blinkImg from '../../assets/mew.svg';
import sleepImg from '../../assets/sleep.svg';
import backgroundNight from '../../assets/background-night.png';
import heart from '../../assets/purr.svg';
import heartRed from '../../assets/hearts-red.svg';
import emoji from '../../assets/emoji-red.svg';
import lowEnergy from '../../assets/no-energy.svg';
import {useDispatch, useSelector} from "react-redux";
import {increment, setAmount} from "./counterSlice";
import {incrementBlink, incrementBlinkByAmount} from "./blinkSlice";
import audio from '../../assets/cat-is-purring.mp3';
import audioSleep from '../../assets/cat_sleeps.mp3';
// import Hex from 'crypto-js/enc-hex';
// import hmacSHA256 from 'crypto-js/hmac-sha256';
import catDayImg from '../../assets/cat-day.png';
import catSleepsImg from '../../assets/cat-inactive.png';
import {
    claimPoints,
    getLoyaltyData, getPointsLimits, registerInAffiliate,
} from "../../logic/server/api";
import {useTelegram} from "../../hooks/useTelegram";
import {incrementLove, incrementLoveByAmount} from "./loveSlice";
import FooterFunction from "../../components/app/functions/FooterFunction";

const Main = () => {
    // const navigate = useNavigate();
    const {webApp, user} = useTelegram();
    const count = useSelector(state => state.counter.count);
    const blink = useSelector(state => state.blink.count);
    const love = useSelector(state => state.love.count);

    // const customer = useSelector(state => state.customer.customer);
    // const loyaltyData = useSelector(state => state.loyalty.loyalty);
    const dispatch = useDispatch();
    const [audioElm] = useState(new Audio(audio));
    const [audioElmSleep] = useState(new Audio(audioSleep));
    const [purrPoints, setPurrPoints] = useState([]);
    const [blinkPoints, setBlinkPoints] = useState([]);
    // const [lovePoints, setLovePoints] = useState([]);
    const [sleepMode, setSleepMode] = useState(false);
    const [sleepPoints, setSleepPoints] = useState([]);
    const [energy, setEnergy] = useState(5000);

    const isStarted = useRef(false);
    const initData = webApp.initData;
    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';
    const shareUrl = `https://t.me/share/url?url=https://t.me/TapCatFunbot` + code + `&text=Join TAPCAT memecoin game!`;

    const customer_id = new URLSearchParams(window.location.search)?.get('customer_id');
    useEffect(() => {
        const imagesPreload = [heart, blinkImg, backgroundNight];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);

    if (((user && user?.id) || customer_id) && isStarted.current !== true) {
        const sleepModeSaved = localStorage.getItem('sleepMode') ?
            JSON.parse(localStorage.getItem('sleepMode')) : false;
        setSleepMode(sleepModeSaved);
        getCustomer();
    }


    async function getAffiliatesData(customerInfo) {
        const resp2 = await getLoyaltyData(customerInfo.customer_id, customerInfo.token);

        if (resp2 && resp2?.data?.reward?.loyalty_points) {
            const points = resp2?.data?.reward?.loyalty_points;
            localStorage.setItem('code', resp2?.data?.referral_code);

            const blinksCount = Math.floor(Number(points) / 10);
            const loveCount = Math.floor(Number(points) / 100);
            dispatch(setAmount(points));
            dispatch(incrementBlinkByAmount(blinksCount));
            dispatch(incrementLoveByAmount(loveCount));
            localStorage.setItem('points', points);
        }
        if (!resp2 || !resp2?.data?.applied) {
            const resp3 = await registerInAffiliate(customerInfo.customer_id, customerInfo.token);
            if (resp3?.referral_code) {
                localStorage.setItem('code', resp3?.referral_code);
            }
        }
    }

    async function getCustomer() {
        const initDataURL = new URLSearchParams(initData);
        const referralCodeInitial = initDataURL?.get('start_param');
        isStarted.current = true;
        const params = new URLSearchParams(window.location.search);
         let customerInfo = {customer_id: params?.get('customer_id'), token: params?.get('token')};
        if ((customerInfo && customerInfo.customer_id && customerInfo.token) || referralCodeInitial || localStorage.getItem('token')) {
            if (customerInfo?.customer_id) {
                localStorage.setItem('token', customerInfo?.token);
                localStorage.setItem('customer', customerInfo?.customer_id);
            }

            if (!customerInfo.customer_id && localStorage.getItem('token')) {
                customerInfo = {customer_id: localStorage.getItem('customer'), token: localStorage.getItem('token')};
            }

            getAffiliatesData(customerInfo);
            // if user go by referral link first time
            if (referralCodeInitial) {
                setTimeout(() => {
                    getAffiliatesData(customerInfo);
                }, 10*1000);
            }
            const resp4 = await getPointsLimits(customerInfo.token);
            const remaining = resp4?.task?.completion?.limits?.reward?.per_user?.per_period?.remaining;
            if (remaining === 0) {
                setSleepMode(true);
                setEnergy(0);
                localStorage.setItem('sleepMode', JSON.stringify(true));
            } else if (remaining) {
                setEnergy(remaining);
                localStorage.setItem('sleepMode', JSON.stringify(false));
            }

        }
    }


    async function claimRequest() {
        if (localStorage.getItem('token')) {
           await claimPoints(localStorage.getItem('token'), 'LS6RH3ZAFH');
        }

    }
            
            const formatPoints = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'B';
    } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 10000) {
        return (num / 10000).toFixed(1) + 'k';
    } else {
        return num.toString();
    }
};



    const countFunction = (event) => {
        const localEnergy = energy ? energy - 1 : 0;
        if (energy !== 0) {
            setEnergy(energy => energy - 1);
        }
        if (localEnergy === 0) {
            setSleepMode(true);
            localStorage.setItem('sleepMode', JSON.stringify(sleepMode));
        }
        if (sleepMode) {
            const x = event.clientX;
            const y = event.clientY;
            setSleepPoints([...sleepPoints, {x, y, id: Date.now()}]);
            if (audioElmSleep.paused) {
                audioElmSleep.play();
            }
            setTimeout(() => {
                if (audioElmSleep.currentTime > 1) {
                    audioElmSleep.pause();
                    audioElmSleep.currentTime = 0;
                }
            }, 1200);

        }

        if (sleepMode) {
            return;
        }
        if (audioElm.paused) {
            audioElm.play();
        }

        dispatch(increment());

        const x = event.clientX;
        const y = event.clientY;
        setPurrPoints([...purrPoints, {x, y, id: Date.now()}]);


        if (localStorage.getItem('points')) {
            localStorage.setItem('points', count + 1);
        }

        if ((count + 1) % 10 === 0 && count !== 0) {
            // setTimeout(() => {
            setBlinkPoints([...blinkPoints, {x, y, id: `${Date.now()}-blink`}]);
            dispatch(incrementBlink());
            // }, 400);

            // setTimeout(() => {
            //     setBlinkPoints([]);
            // }, 600);
        }

        if ((count + 1) % 100 === 0 && count !== 0) {
            dispatch(incrementLove());
        }
        claimRequest();


        setTimeout(() => {
            if (audioElm.currentTime > 1) {
                audioElm.pause();
                audioElm.currentTime = 0;
            }
        }, 1200);

    }

  const removePoint = (id) => {
    setPurrPoints(purrPoints.filter((point) => point.id !== id));
  };
  const removeBlinkPoint = (id) => {
    setBlinkPoints(blinkPoints.filter((point) => point.id !== id));
  };

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "https://telegram.org/js/telegram-widget.js?15";
  //   script.setAttribute("data-telegram-login", "PurrCoingameBot");
  //   script.setAttribute("data-size", "large");
  //   script.setAttribute("data-radius", "20");
  //   script.setAttribute("data-auth-url", "/purr");
  //   script.setAttribute("data-request-access", "write");
  //   document.getElementById("telegram-login-container").appendChild(script);
  // }, []);
  //
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://test-widget.bots.work/widget/widget.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   document.body.appendChild(script);
  //
  //   script.onload = () => {
  //     const w = document.createElement("chat-widget");
  //     w.setAttribute("bot", "PT37KG6HC5");
  //     w.setAttribute("token", "XkcEOfGAbLLauKFmchhEvo73T");
  //     document.body.appendChild(w);
  //   };

//     setTimeout(() => {
//         const btn = document.getElementsByClassName(
//             "chat-button chat-button-hidden",
//         )[0];
//         if (btn) {
//             btn.click();
//         }
//     }, 1000);
// }, []);


return (
        <TelegramScreen
            className={`main-wrapper--inactive ${sleepMode ? '' : 'main-wrapper' }`}  id='gesuredZone'>
            {/*<div id="telegram-login-container"></div>*/}

            <div className='main-container'>
                {/*<TelegramHeader>*/}
                {/*<div className={'top-container'}>*/}
                <div>
                    <div className={`forms__top ${sleepMode ? 'forms__top--sleep-mode' : ''}`}>
                        <a className={`text-medium forms__top-text ${sleepMode ? 'forms__top-text--sleep-mode' : ''}`}
                           href={shareUrl} target="_blank" rel="noreferrer"
                        >Invite friends and earn more coins</a>
                        <img src={arrowBlack} alt='arrow'
                             className={`forms__top-img ${sleepMode ? 'forms__bottom-icon--sleep-mode' : 'forms__bottom-icon--sleep-mode-blk'}`}></img>
                        <img src={arrow} alt='arrow'
                             className={`forms__top-img ${sleepMode ? 'forms__bottom-icon--day-mode-blk' : 'forms__bottom-icon--day-mode'}`}></img>
                    </div>
                </div>
                {purrPoints.map((purr) => (
                    <img className={`coin__left animation__coin-left`}
                         key={purr.id}
                         style={{left: purr.x - 10, top: purr.y - 10}}
                         alt='hearts' src={heart}
                         onAnimationEnd={() => removePoint(purr.id)}
                    >
                    </img>
                ))}
                {blinkPoints.map((blink) => (
                    <img className={`coin__right animation__coin-right`}
                         key={blink.id}
                         style={{left: blink.x - 80, top: blink.y - 10}}
                         alt='line' src={blinkImg}
                         onAnimationEnd={() => removeBlinkPoint(blink.id)}
                    >
                    </img>
                ))}
                {sleepPoints.map((sleep) => (
                    <img className={`sleep-card animation__sleep-card`}
                         style={{left: sleep.x - 150, top: sleep.y - 10}}
                         alt='line' src={sleepImg}
                         key={sleep.id}
                    >
                    </img>
                ))}
                {/*<div className='forms__middle'>{responseJson.current}</div>*/}
                {/*<div className='forms__middle'>{responseJson2.current}</div>*/}
                <div className={'forms__middle-container'}>
                <img src={sleepMode ? catSleepsImg : catDayImg} alt='arrow'
                     className='forms__middle-image' onClick={countFunction}></img>
                </div>
                {/*</div>*/}
                <div className='forms__bottom'>
                    <div className='forms__bottom-big-card'>
                     <p className='forms__bottom-text-title text-bold'>
    {!!count && formatPoints(count)}
</p>

                        <p className={`forms__bottom-text text-bold ${sleepMode ? 'forms__bottom-text--blocked' : ''}`}
                        >PURR</p></div>
                    <div className='forms__bottom-inner-container'>
                        <div  className={`forms__bottom-small-card ${sleepMode ? 'forms__bottom-small-card--sleep-mode' : ''}`}>
                            <img src={lowEnergy} alt='energy' className={`forms__bottom-icon ${sleepMode ? 'forms__bottom-icon--sleep-mode' : 'forms__bottom-icon--sleep-mode-blk'}`}></img>
                            <img src={lightningImg} alt='energy'  className={`forms__bottom-icon ${sleepMode ? 'forms__bottom-icon--day-mode-blk' : 'forms__bottom-icon--day-mode'}`}></img>
                            <div className={`forms__bottom-results-container ${sleepMode ? 'forms__bottom-icon--day-mode-blk' : 'forms__bottom-icon--day-mode'}`}>
                                <div className='forms__bottom-result text-regular'>{energy}</div>
                                <div className='forms__bottom-result-title text-regular'>Energy</div>
                            </div>
                            <div className={`forms__bottom-results-container ${sleepMode ? 'forms__bottom-icon--sleep-mode' : 'forms__bottom-icon--sleep-mode-blk'}`}>
                                <div className='forms__bottom-result text-regular'>No energy</div>
                                <div className='forms__bottom-result-title text-regular'>Boost now!</div>
                            </div>
                        </div>
                        <div className='forms__bottom-small-card forms__bottom-small-card--small'>
                            <img src={heartRed} alt='heart'  className={`forms__bottom-icon ${sleepMode ? 'forms__bottom-icon--sleep-mode' : 'forms__bottom-icon--sleep-mode-blk'}`}></img>
                            <img src={heartImg} alt='heart' className={`forms__bottom-icon ${sleepMode ? 'forms__bottom-icon--day-mode-blk' : 'forms__bottom-icon--day-mode'}`}></img>
                            <div className='forms__bottom-results-container'>
                                <div className='forms__bottom-result text-regular'>{love}</div>
                                <div className='forms__bottom-result-title text-regular'>Love</div>
                            </div>
                        </div>
                        <div className='forms__bottom-small-card forms__bottom-small-card--small'>
                            <img src={emoji} alt='blinks'
                                 className={`forms__bottom-icon ${sleepMode ? 'forms__bottom-icon--sleep-mode' : 'forms__bottom-icon--sleep-mode-blk'}`}></img>
                            <img  className={`forms__bottom-icon ${sleepMode ? 'forms__bottom-icon--day-mode-blk' : 'forms__bottom-icon--day-mode'}`}
                                  src={emojiImg} alt='blinks'></img>
                            <div className='forms__bottom-results-container'>
                                <div className='forms__bottom-result text-regular'>{blink}</div>
                                <div className='forms__bottom-result-title text-regular'>Blinks</div>
                            </div>
                        </div>
                    </div>
                    {/*<div className='forms__disclaimer'>*/}
                    {/*    Cats make no promise of da hooman money. You give taps.<br className='breakline'></br>*/}
                    {/*    We make purrs, blinks, n love. Cat is un financial advisor.<br className='breakline'></br>*/}
                    {/*    Kat fur game may look different in future as updoots are mead.*/}
                    {/*</div>*/}
                    <FooterFunction currentPage={'main'}/>

                </div>
            </div>
        </TelegramScreen>
    );
};

export default Main;
